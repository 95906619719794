import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';
import CoreValues from '../components/CoreValues';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "About Us | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/14.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Who We Are</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-c'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
{/* <p className='home-p'>Welcome to <strong>Green Origin Tours - Africa Ltd,</strong> a tour & travel operator offering tailor made safaris. We are one of East Africa's finest tour operators offering a wide range of tours at affordable prices! We would like to invite you on a journey of adventure through East Africa so you can have a memorable safari. We will arrange the vacation of your choice from the popular gorilla tours in East Africa to the legendary traditional safaris.
</p>

<p className='home-p'>
We make you experience East Africa’s great lakes and rivers, unspoiled national parks, tropical rain forests and authentic cultural experiences. We customize all our tour packages to your exact requirements. As prominent tour operators, we blend professional services with our personal expertise and experiences.
</p> */}

<p className='home-p'>
Echoes from African Jungles, the noise of the restless natives of the wilderness create experiences that; no two safaris, no two game drives are ever the same. The sounds and sights of Queen Elizabeth national park, Murchison falls which is the world’s powerful waterfall, the never-ending array of landscapes , the natives you meet there create a nostalgic feeling that keep our visitors coming back. Half of our visitors are referral travelers always. 
</p>

<p className='home-p'>
Green Origin Tours - Africa Ltd is committed to building first hand knowledge of every destination, lodge and experiences. A journey to the African wilderness with tested experts –we ensure that you enjoy a trip of a life time. Our team of safari experts understand what goes in making every trip to Africa once-in-a lifetime experience. We will not show you the whole world, but we will show you Africa better than anyone else. 
</p>

<p className='home-p'>
Arrange your holiday travel with us and we will be along side with you from our very first contact, offering 24 hour assistance and local know-how from the right time zone. Follow your dream and spend time with the restless natives of the wild or simply enjoy the evening sundowner drinks on an endless green jungles of the Equator belt. We are determined to help you create the best safari experience to immerse you in the magic of Africa wilderness.
</p>

<p className='home-p'>
Green Origin Tours - Africa Ltd is determined to ensure 100% value for money and we go further in our Corporate Social Responsibility (CSR) to ensure that we support the local inhabitants and conservation of endangered species like the Gorillas. When your vacation travel brings you to Africa wilderness, Savannah grass land of the Sub-Saharan Africa or you need Inspiration, information or advice, you can bet us on to create your long-awaited travel experience. It will be nice to see you. 
</p>

<p className='home-p'>
We are Africa’s best safari experts committed to building first hand knowledge of every destination, lodge and activity we recommend from our 50+ safari partners. We live and breath-providing our clients with unbiased advice and amazing experiences. Our safari experts provide world class travel advice and focus exclusively on what is right for you. 
</p>

<p className='home-p'>
Our best price promise: Being 100% Uganda owned and operating company allows us to offer clients the best possible price guarantee for all our tours, safaris and lodges, without affecting the quality of your itinerary. You will always get the best value with us in one complete tailor made itinerary. We are confident of our rates, that we offer our best price promise. Green Origin Tours - Africa Ltd is a sustainable luxury travel operator offering safaris and journeys in some of the most breathtaking, remote wild destinations in the world. 
</p>

<p className='home-p'>

From A-Z, we take care of every detail, your flight booking, tickets, visa application, passports (for Ugandan Citizens), activities, accommodation, transfers, airport pickup/drop off, group tours and more. We are your full service partner for vacations and our passionate tour experts take care of every little detail making sure you have the most memorable safari. 

</p>

<p className='home-p'>

We are excited to take you on a journey to experience sights and sounds found no where else on earth. Unique scenery, wildlife and cultures are waiting to be revealed. And we at Green Origin Tours - Africa Ltd can’t wait to share time with you. Our customer designed journeys are intended to inspire but can be tailor made to suit your every need. 

</p>

<h3 className='inner-h3 inner-h3-a'>IT’S TIME TO LET YOUR SAFARI DREAM RUN WILD!</h3>

<h3 className='inner-h3 inner-h3-a inner-h3-b'>Mission</h3>
<p className='home-p'>

To deliver quality services to our clients around the world.

</p>


           </div>
           {/* <div className='bubu-logo-cover'>
                     <img src='./images/19.jpg' className='bubu-logo'></img>
           </div> */}
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   <section className='bg-cover'>
      <div className='content-container content-container-d'>
        <h3 className='inner-h3 inner-h3-a inner-h3-b'>Core Values</h3>
    <CoreValues></CoreValues>
    </div>
   </section>
    
  
    </>
  )
}

export default Home