import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "2 Days Mount Elgon Safaris | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/20.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>2 Days Mount Elgon Safaris</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Kampala - Mbale.</span></strong> This trip begins in Kampala and ends in Kampala. We transfer to Mbale via Kampala-Jinja road. This journey will reward you with Mabira forest, Lake Victoria, Jinja town among other things. Stop briefly in Mbale for lunch and proceed to explore the magnificent Sipi falls. Dinner and overnight at Mbale Resort Hotel (Lodge facility) or Sipi Falls rest camp (Budget facility) Meal plan (H/B).
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/33.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Hiking Elgon.</span></strong> Wake up early to prepare for Mount Elgon hiking. Leave with packed lunch and meet with the park guides and porters. You may begin off with any trail like Budadili Forest exploration center, Kapkwai or Kapwata; ascend via any of these trails.Return to Kampala where you will be dropped off to your hotel or place of residence or back to Entebbe Airport for your flight back home. Meal plan (H/B) End of Safari.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/34.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home