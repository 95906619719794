import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "6 Days Tanzania Wildlife Safari | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/8.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>6 Days Tanzania Wildlife Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Airport pick-up.</span></strong> Our staff will pick you and you will be driven to the Arusha Crown Hotel and briefed on your upcoming safari. Enjoy a restful night before beginning your adventure the next day!
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/17.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Tarangire National Park.</span></strong> After having breakfast in your hotel, you will be picked up by your driver guide and depart for Tarangire National Park. Tarangire is one of the largest parks in Tanzania and is located in the Manyara region. It is characterized by the large herds of elephants and tree climbing lions that call it home, as well as the fascinating baobab trees that are renowned for their mythical powers by some of the local cultures. The Tarangire River flows through the park year round and is the only source of water for the animals during the dry season.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/18.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Serengeti National Park.</span></strong> After breakfast you will depart for Serengeti National Park with a picnic lunch in hand. You will enjoy a game drive en route. The Serengeti is famed for its incredible wildlife, stunning views and the annual migration of over 1.5 million wildebeest and hundreds of thousands of zebras. In this frenzied bout of territorial conquests and mating, witness an actual survival of the fittest as these animals plunge through crocodile-infested waters. The wildebeest will birth more than 8,000 calves daily before the 1,000 km (600 mile) pilgrimage begins again.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/47.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 4: Serengeti National Park.</span></strong> After breakfast you will set off on a full day game drive in the wilderness following the Great Migration trails. Our experienced driver tour guide will provide you with interesting facts about this highly renowned event, which makes Tanzania the top safari destination in Africa. Serengeti, a UNESCO world heritage site, is home to around 200,000 zebras, and 300,000 Thomson’s gazelle, which join in the wildebeest’s trek for fresh grazing. Listen to the exotic sounds of over six million hooves pounding the open plains. This is just like you imagined Africa to be!
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/48.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 5: Ngorongoro Crater.</span></strong> After breakfast you will head towards the Ngorongoro Conservation Area for a game drive. This is another UNESCO World Heritage Site located west of Arusha in the crater highlands of Tanzania. The area is named after the Ngorongoro Crater, the world's largest caldera. The stunning landscape of Ngorongoro, with its vast expanses of highland plains and concentration of wildlife, animals makes this place one of the most exotic natural wonders on earth.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/6.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 6: Lake Manyara National Park to Arusha.</span></strong> After breakfast you will drive to Lake Manyara National Park, whose lake Ernest Hemingway claimed was “the loveliest I had seen in Africa”. The park offers a compact game viewing circuit and is one of the most diverse reserves in the country. The lake itself covers two-thirds of the park and the Great Rift Valley provides a stunning backdrop. Over 400 species of birds can be found in the Manyara, including thousands of pink-hued flamingos on their perpetual migration, as well as other large waterbirds such as pelicans, cormorants, and storks. In the afternoon you will head back to Arusha, where you will be dropped off at your hotel or the airport.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/29.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home