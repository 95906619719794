import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaEnvelope,FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(#46450c,#46450c), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='/' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>
                        {/* <div className='footer-link'>
                              <Link to='#' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div> */}

                        <div className='footer-link'>
                              <Link to='who-we-are' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Who We Are</p></Link> 
                        </div>
                         <div className='footer-link'>
                                <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link>
                        </div>
                        <div className='footer-link'>
                                <Link to='gallery' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link>
                        </div>
                         <div className='footer-link'>
                                <Link to='tips-to-note' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Tips To Note</p></Link>
                        </div>
                        <div className='footer-link'>
                                <Link to='q-and-a' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Q & A</p></Link>
                        </div>

  
                    </div>

             </div>

            

             <div className='footer-inner-content'>
                    <h3>Safaris</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='uganda-safaris' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Uganda</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='kenya-safaris' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Kenya</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='tanzania-safaris' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Tanzania</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='rwanda-safaris' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Rwanda</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='south-sudan-safaris' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>South Sudan</p></Link> 
                        </div>

                        
                      
                      
                    </div>

             </div>

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Our Address</h3>
                      <p>
                  Lubowa-Kyeyagalire, Seguku Ward, Ndejje Division, Wakiso District<br></br>
                  P. O. Box 141098, Kampala - Uganda
                        </p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Contact Us On</h3>
                      <p>
                      +256 (0) 779 350071<br></br>
                      +256 (0) 702 341937

                      </p>
                      <p>
                        <span className="top-icon"> <FaEnvelope></FaEnvelope></span>
            <span className='email-link'>
             tour@greenorigintoursuganda.com
              {/* info@fordwichcompany.com | fordwichcompany@gmail.com */}
            </span>
                      </p>

                    </div>
                     

                   
                      

             </div>

          <div className='footer-inner-content'>


                     {/* <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='#contact' onClick={scrollFunc} className='email-link'>  <p>info@pinnacleconceptsltd.com</p></Link> 
                      
                      </div> */}
                      <div className='footer-social-icons'>
                         
                              
                        
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                              <span>
<a href="https://www.linkedin.com/company/green-origin-tours-africa-ltd" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span> 
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 {/* <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span> */}

                                  
                         </div>
                        
                        
                      </div>
                       {/* <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  
                   <p>info@fordwichcompany.com<br></br>
fordwichcompany@gmail.com</p>
                   </Link> 
                      
                      </div> */}
                       <div className='footer-social-icons footer-social-icons-a'>
                        <Link to='booking' className='inquiries-link' onClick={scrollFunc}>Book Now</Link>
                        {/* <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a> */}
                      </div>
                     

                      {/* <div className='footer-social-icons'>
                        <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a>
                      </div> */}

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Green Origin Tours - Africa Ltd | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer