import React, {useEffect} from 'react'
import Tool8 from '../components/Tool8';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';
import HomeVideo from '../components/HomeVideo';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Gallery | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
   
       <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Gallery</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
{/* Cards Start */}
            <div className='services-container-new'>
         
            <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/65.jpg' class='safari-image safari-image-a'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Ankole Cows</h3>
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/66.jpg' class='safari-image safari-image-a'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Uganda Equator</h3>
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/67.jpg' class='safari-image safari-image-a'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Geishure</h3>
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
          <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/68.jpg' class='safari-image safari-image-a'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Larakaraka Acholi Dance</h3>
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
          <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/69.jpg' class='safari-image safari-image-a'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Mango Sales</h3>
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>

           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
              
              <div className='tools-slides-cover'>
                 {/* <img src='./images/73.jpeg' class='safari-image safari-image-a'></img> */}
                <Tool8></Tool8>
              </div>
           {/* <h3 className='skill-h3 service-h3-new service-h3-new-a'>Seasonal wishes from one of the Directors</h3> */}
            
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>

          
       
            
      </div>

      {/* Cards End */}

   

      {/* Modals End */}

         </div>
    </section>
  
    </>
  )
}

export default Home