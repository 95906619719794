import React from 'react'

let ValuesData = [

{title: "Excellence",      
content: "Determined to deliver services to our clients, way above the ordinary. Without our clients, we wouldn’t exist. When it comes to our travelers, every detail including the very finest, matters. It goes further to motivate us to offer only the very best experience – one that will forever hold on their memories, leaving an indelible mark for life."
},

{title: "Integrity",      
content: "Our reputation precedes us and we are among the best standing in the pearl of Africa. We place trust in each other, we carefully craft our travel experiences to the satisfaction of our clients. Today’s culture is badly focused on generating money, but the ethos of money first is shorthanded. We go above and beyond, guided by our core values which ultimately determine the way we treat each other, our clients and partners."
},
{title: "Innovativeness",      
content: "We don’t settle, we go further in crafting the best, out of ordinary travel experience for our clients-painting a life-long-golden touch-memory on all of our clients."
},
{title: "Team Work",      
content: "Our personality, attitude  and values shines through when we communicate and blend to deliver quality service as a team, and deliver  quality advise and smart recommendations with our brand voice and tone. We make sure our visitor arrive with preset expectations that fill their passion for adventure. Our approach is personal and we take great care of each and every one of our relationships, to ensure we always deliver the highest quality result and share our joint success."
},
{title: "Client Care",      
content: "We take the pride in producing high quality travel experiences, remaining in the constant state of readiness, value for money, customer care and technical support handled within our call-centre."
}
];

export default ValuesData;