import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Clients | Fordwich Company Limited";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2 home-service-h2 clients-h2">Our Clients</h2>
           <section className='section-cover who-we-are-cover' >
     
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                   
                    <article className='vision-mission-info'>
                      
                          {/* <h3 className='title-h3'>Core Values</h3>
                          
                           <div className='vm-line'></div> */}
                           <ul className='cv-ul cv-ul-a'>
                           <li> NBS TV.</li>
<li>Enabel.</li>
<li>CivSource Africa.</li>
<li>Exhale Safari Hotel - Mbarara.</li>
<li>National Teachers College – Kabale, plus many more.</li>
                           </ul>
                          {/* <p>To be a world-class and global manufacturer and distributor of office and institutional supplies.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To produce and deliver world class quality products and provide excellent and reliable services to all our customers.</p> */}

                    </article>

                    


                 </div>

                 {/* <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                            <li>Customer Satisfaction.</li>
                            <li>Communication.</li>
                            <li>Ethical Practices & Honesty.</li>
                            <li>Quality Products & Safe Environment.</li>
                            <li>Appreciation & Recognition of our People.</li>
                            <li>Profitable & Growing Business.</li>
                          </ul>
                          
                          

                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div> */}
          </div>
    </section>

    </section>
  )
}

export default Services