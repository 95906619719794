import HomeVideo1 from '../components/video/video1.mp4'
import {Link} from 'react-router-dom';
const HomeVideo = () => {
  return (
    <div  className='home-video'>
      <div className='overlay'></div>
         <video src={HomeVideo1} autoPlay loop muted className='home-video1'></video>
       <div className='hero-text'> 
        <h2>Visit</h2>
        <div className='line-a'></div>
        <h1 className="home-h1">East Africa On Safari With Us</h1>
        <div className='line-a'></div>
        <p className='home-hero-p'>Our company offers safaris to all the other East African countries. Let us plan your holiday now!</p>
        <div className='btns-cover'>
             <Link to='about' className='tours-link'>About Us</Link>
             <Link to='services' className='tours-link tours-link-a'>Our Services</Link>
        </div>
        </div>
        

    </div>
  )
}
export default HomeVideo