import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "3 Days Murchison Falls Safari | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/24.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>3 Days Murchison Falls Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
       <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-c'>
           
          
            <div className='home-info home-info-a'>
          <h2 className="home-h2 tips-h2">Visit the Cauldron at Murchison Falls National Park</h2>
   

                
<p className='home-p'>
 Murchison Falls is Uganda’s oldest and largest conservation area. The park is named after the mighty Murchison Falls, where the Nile River is forced through a narrow, 8 meter wide gorge, known as the Devil’s Cauldron,  creating a trademark rainbow and  giving rise to some of the most beautiful sights in the world. This is also an exceptional place to see wildlife and embark on a number of thrilling activities.
</p>

<p className='home-p'>
  The 1951 film “The African Queen” starring Humphrey Bogart was filmed on Lake Albert and the Nile in Murchison Falls National Park. Notable visitors to the park include Winston Churchill, Theodore Roosevelt, Ernest Hemingway, and several British royals.

</p>

<p className='home-p'>
  The park is endowed with several attractions and is home to four members of the Big Five (lions, leopards, elephants, and buffaloes), the world’s second largest reptile (the Nile Crocodile), as well as over 450 recorded bird species, The bird checklist with the shoebill as the most common along the river stretch approaching the Albert delta and many profolic bird species occupy the park.The white crested turaco,red winged grey warbler,all asociated with woodland in the southern bank.

</p>

<h3 className='inner-h3'>Top 5 Things to do at Murchison Falls National Park</h3>
<p className='home-p'>
 1. <strong>Go on a boat safari</strong> and enjoy spectacular sights of hippos, crocodiles, water monitors, as well as the other animals that gather along the river.
<br></br>
2. <strong>Spend a couple of hours with man’s closest living relative</strong> as you enjoy a guided walk in search of the habituated chimpanzee family that call the park home.
<br></br>
3. <strong>Enjoy the wildlife.</strong> Elephants, buffalo, lions, and leopards all reside in the park. Other special sightings include the Ugandan Kob, Jackson hartebeest and the Rothschild giraffe.
<br></br>
4. <strong>Indulge in some birding activities,</strong> and go in search of the elusive bird species such as the Paradise Flycatcher, Chocolate-backed Kingfisher, and prehistoric shoebill stork.
<br></br>
5. <strong>Visit Murchison Falls,</strong> one of the main attractions of the park, which offers magnificent views and sightings of several wildlife species including buffalos, hippos, crocodiles, waterbucks, and birds.
</p>
<h3 className='inner-h3'>Build Your Own Safari at Murchison Falls National Park</h3>
<p className='home-p'>
 At Green Origin Tours, our customizable Murchison Falls National Park itineraries offer the utmost personalization for your activities and a timetable based on your exact specifications. Whether you want to design an entire safari itinerary from scratch, or modify an existing one, we ensure all your desires and needs are met.

</p> 
  
           </div>
           {/* <div className='bubu-logo-cover'>
                     <img src='./images/19.jpg' className='bubu-logo'></img>
           </div> */}
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Transfer to Murchison Falls National Park.</span></strong> We will pick you and head straight for the park When we get to Masindi, we will drive through the National Park until we reach Paraa Lodge. We will make a stop Ziwa Rhino Sanctuary and check out the endangered white rhinos before heading to Fort Murchison were we will check in and rest.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/24.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Game Drive & Boat Cruise.</span></strong> There are so many animals to see like Hippos, Elephants, Crocodiles, Buffaloes and several different bird species. We will then move on to the game drive where we cross the Nile. You can’t miss the Lions or the Giraffes. Also on the menu is a boat ride to the bottom of the falls and then a hike back to the top were a guide will be waiting.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/2.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Back To Kampala.</span></strong> Right after breakfast, we will travel back to Kampala stopping on the way to take in the great views, lunch and to pick up some souvenirs.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/7.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home