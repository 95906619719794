import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "4 Days Queen Elizabeth Safari | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/11.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>4 Days Queen Elizabeth Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-c'>
           
          
            <div className='home-info home-info-a'>
       
   

                
<p className='home-p'>
 Like any other African country, a tour to see wildlife in Uganda is very much possible and Uganda has parks that are close to the city Centre like; Lake Mburo national park, Queen Elizabeth national  park and Murchison falls national park.  To explore these parks well during your tour with us, we would recommend a 3 days wildlife safari like’; the 3 days Murchison falls tour or the 3 day Lake Mburo tour. If time is against you a 2 days Queen Elizabeth safari is also possible.
</p>
<p className='home-p'>
 Queen Elizabeth National Park spans the equator line; monuments on either side of the road marking the exact spot where it crosses latitude 00. The park is home to over 95 mammal species and over 600 bird species. Queen Elizabeth National Park is understandably Uganda’s most popular tourist destination.The park’s diverse ecosystems, which include sprawling savanna, shady, humid forests, sparkling lakes, and fertile wetlands, make it the ideal habitat for a classic big game, ten primate species including chimpanzees, and over 600 species of birds.
</p>
<p className='home-p'>
 It takes only two hours of driving after gorilla trekking for visitors to visit Queen Elizabeth National Park to spot elephants, crocodiles, Hippopotamus, the Uganda Kob, Antelopes, Buffaloes and the rare tree climbing lions in the Ishasha sector of the park. Moreover if one missed the chimps during gorilla trekking, they can go for chimpanzee trekking at the unique Kyambura gorge or visit the salt mining lake near the park to spot the beautiful Flamingo birds. This proximity to Queen Elizabeth National Park allows our visitors to easily combine gorilla trekking and game drives making for a true amazing African wildlife safari.
</p>
<p className='home-p'>
 Therefore, <em>Green Origin Tours - Africa Ltd</em> should be on your bucket list because you will not only enjoy the different activities that are famous and populous but also be surrounded by some of the most smiling and welcoming people in the world.
</p>
<h3 className='inner-h3 inner-h3-a'>Uganda’s Queen Elizabeth National Park is truly a Medley of Wonders!</h3>


           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Transfer To Queen Elizabeth National Park.</span></strong> We will pick you up from your hotel in the morning and drive out to the park via Fort Portal. We will reach in the afternoon and once you are settled into the hotel, we will go for a late afternoon game drive.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/11.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Game Drive & Boat Ride.</span></strong> We will drive through the park taking in everything nature has to offer at close range. The herds of kobs, leopards, lions and wild hogs and so many other wild animals. We will then take a two-hour boat-ride on the Kazinga Channel for a breathtaking view of Crocodiles, Hippos, Storks and Pelicans, and so many others waddling around in the water.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/14.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Tree Climbing Lions.</span></strong> On this this day we let you explore tree climbing lions. The tree climbing lions in Uganda are part of the wider lion family. Lions in general help balance the eco-system by maintaining the population of herbivores animals like Buffaloes and the different species of antelopes. Lions also prey on weaker and sick animals helping reduce the spread of diseases and parasites. 
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/64.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 4: Back To Kampala.</span></strong> In the morning, we will have breakfast at the Lodge and proceed back to Kampala. We will make a few stops on the way so you can pick up a few souvenirs and have lunch.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/16.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home