import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "3 Days Bwindi Gorilla Trekking | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/23.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>3 Days Bwindi Gorilla Trekking</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-c'>
           
          
            <div className='home-info home-info-a'>
          <h2 className="home-h2 tips-h2">Bwindi Impenetrable Goriila National Park</h2>
   

                
<p className='home-p'>
 The Bwindi Impenetrable Forest is a large primeval forest located in south-western Uganda. The Bwindi forest is on the edge of the Albertine Rift, the western branch of the Rift valley, at elevations ranging from 1,160 to 2,607 meters (3,806 to 8,553 ft). The forest contains around 160 species of trees and over 100 species of ferns. Its mist covered hillsides are blanketed by one of Uganda’s oldest and most biologically diverse rainforests.
</p>

<p className='home-p'>
  Bwindi impenetrable national park in Uganda, Virunga National park in the D.R Congo Volcanoes National park in Rwanda and Mgahinga are special because this host half of the world’s remaining population of mountain gorillas.

</p>

<p className='home-p'>
  Bwindi impenetrable forest national park is a UNESCO World heritage site since 1994 because of its great biodiversity, which dates back over 25,000 years. More famously, this forest also protects an estimated 320 mountain gorillas- roughly half of the world’s gorilla population. Apart from mountain gorillas, Bwindi currently has 120 species of mammals which include Antelopes, Giant forest pigs, the African Golden cat, the side –stripped jackal and African civet.

</p>

<p className='home-p'>
  You will also find several primates in the park including Chimpanzees, Lhotse’s monkeys and black/white colobus monkeys. Over 220 species of butterflies and 310 of birds can be spotted in the great forest. Some of the notable bird species include the Frazer’s eagle owl, African green broadbill, Brown necked parrots and the white tailed blue flycatcher.

</p>

<h3 className='inner-h3'>What to See & Do</h3>
<p className='home-p'>
 Gorilla tracking is the parks’ main tourist attraction. This much sought out activity by Primate Lovers is only possible in Bwindi Impenetrable and Mgahinga national parks at the Ugandan side.
</p>
<p className='home-p'>
 Tourists wishing to track gorillas must first obtain a permit to do so. Notably this unique experience can mean to be strenuous – involving hiking through rough and steep terrain to locate the gorilla families.
</p>
<p className='home-p'>
 However, some of the gorilla groups in Bwindi live very near to the starting points and finding them takes less than two-hours.
</p>
<p className='home-p'>
 Did I forget to mention that gorilla trekking in Mgahinga has fewer crowds?  Yes. There are fewer visitors with less crowds during the briefing and after gorilla trekking, and this to some visitors may allow more privacy to peacefully enjoy the great scenery around the park.
</p>
<p className='home-p'>
 Our visitors can combine gorilla trekking with golden – monkey tracking. However having the cute golden monkeys is not a complete knock-out for Mgahinga because Bwindi has got Chimpanzees too - in that case.
</p>
<p className='home-p'>
There are also three volcanoes in Mgahinga to choose from –Mt.Muhavura, Mt.Sabinyo and Mt. Gahinga. 
</p>
<p className='home-p'>
Climbing on top of these volcanoes will reward our visitors with stunning views and opportunities to spot the birds.
</p>
 
  
           </div>
           {/* <div className='bubu-logo-cover'>
                     <img src='./images/19.jpg' className='bubu-logo'></img>
           </div> */}
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Travel to Bwindi forest.</span></strong> Your day will start after an early breakfast and you will embark on your gorilla safari southwards to Bwindi impenetrable forest. The journey will offer you highway sights of rural and semi-urban Uganda with several fruit & vegetable markets along the roads, craft and drum makers and a stop at Uganda’s Equator monument where you will cross to the southern hemispheres. After the experience at the Equator, the drive will continue with a stop over in Mbarara for lunch before proceeding to Bwindi with short bathroom stopovers and picture sessions of landscape and other attractions.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/23.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Gorilla trekking in Uganda.</span></strong> Uganda Gorilla trekking safari – Today early morning your driver guide will pick you up from the lodge and transfer you to the briefing point at 7am before you start your gorilla trek at 8 am. At the briefing point, the Uganda wildlife authority rangers will welcome you and given seats until time of briefing as your driver guide registers you with the ranger on duty to be allocated a gorilla family. Eventually the ranger guide will share a short briefing about the experience in the park and how you should interact with nature and the people. You will then be allocated a gorilla family to trek after which you will start the walk into the forest. Depending on location of your gorilla family, you might need to drive to the trail head of the allocated gorilla family.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/40.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Transfer to Entebbe.</span></strong> We drive you back to Kampala. Have lunch at the Equator and enjoy picture sessions in both the northern and southern hemispheres. This will mark the end of your lovely Uganda holiday.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/41.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home