import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/About';
import Team from './pages/Team';
import CoatHangers from './pages/3DMTT';
import SofaSets from './pages/3DMGS';
import Equipment from './pages/Equipment';
import Products from './pages/4DKWS';
import HomeFurniture from './pages/Home-Furniture';
import Beds from './pages/3DKCS';
import Wardrobes from './pages/3DMM';
import Services from './pages/NewServices';
import Clients from './pages/NewClients';
import Gallery from './pages/NewGalleryA';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import DesktopComputers from './pages/Water-Dispensers';
import OfficeSofas from './pages/3DANPS';
import Desks from './pages/3DRGS';
import OfficeChairs from './pages/6DTWS';
import CabinetsShelves from './pages/3DSWS';
import BoardroomTables from './pages/4DGLB';
import DiningTables from './pages/3DQET';
import CenterTable from './pages/3DBGT';
import TVStands from './pages/3DMRH';
import KitchenCabinets from './pages/3DSFS';
import CB from './pages/2DLM';
import Curtains from './pages/2DME';
import MN from './pages/1DSN';
import Semuliki from './pages/3DSNP';
import RW from './pages/Rwanda';
import TZ from './pages/Tanzania';
import Kenya from './pages/Kenya';
import SS from './pages/SouthSudan';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";
import Tips from "./pages/Tips";
import QA from "./pages/QA";

function App() {
  return (
    <BrowserRouter>
    <TopSection></TopSection>
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='who-we-are' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='our-team' element={<Team></Team>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='gallery' element={<Gallery></Gallery>}></Route>
             <Route path='equipment' element={<Equipment></Equipment>}></Route>
             
            <Route path='certifications' element={<Certification></Certification>}></Route>
             <Route path='booking' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='power' element={<Power></Power>}></Route>
 <Route path='desktop-computers' element={<DesktopComputers></DesktopComputers>}></Route>
 <Route path='three-days-mundari-tribe-tour' element={<CoatHangers></CoatHangers>}></Route>
  <Route path='three-days-akagera-national-park-safari' element={<OfficeSofas></OfficeSofas>}></Route>
  <Route path='three-days-rwanda-gorilla-safari' element={<Desks></Desks>}></Route>
  <Route path='six-days-tanzania-wildlife-safari' element={<OfficeChairs></OfficeChairs>}></Route>
  <Route path='three-days-serengeti-wildlife-safari' element={<CabinetsShelves></CabinetsShelves>}></Route> 
   <Route path='four-days-gorillas-lake-bunyonyi' element={<BoardroomTables></BoardroomTables>}></Route>
   <Route path='three-days-kidepo-wildlife-safari' element={<Products></Products>}></Route>  
   <Route path='three-days-semuliki-national-park-safari' element={<Semuliki></Semuliki>}></Route>
   <Route path='three-days-mgahinga-gorilla-safari' element={<SofaSets></SofaSets>}></Route>  
  <Route path='four-days-queen-elizabeth-safari' element={<DiningTables></DiningTables>}></Route>      
  <Route path='three-days-kibale-chimpanzee-safari' element={<Beds></Beds>}></Route>        
  <Route path='three-days-murchison-falls-safari' element={<Wardrobes></Wardrobes>}></Route> 
 <Route path='three-days-bwindi-gorilla-trekking' element={<CenterTable></CenterTable>}></Route>
 <Route path='thirteen-days-rwenzori-trekking-gorillas' element={<TVStands></TVStands>}></Route> 
 <Route path='three-days-sipi-falls-safari' element={<KitchenCabinets></KitchenCabinets>}></Route> 
 <Route path='two-days-lake-mburo-safari' element={<CB></CB>}></Route>  
<Route path='two-days-mount-elgon-safari' element={<Curtains></Curtains>}></Route>
<Route path='one-day-source-of-the-nile-safari' element={<MN></MN>}></Route>  
<Route path='south-sudan-safaris' element={<SS></SS>}></Route>  
<Route path='rwanda-safaris' element={<RW></RW>}></Route>
<Route path='tanzania-safaris' element={<TZ></TZ>}></Route>  
<Route path='kenya-safaris' element={<Kenya></Kenya>}></Route>
<Route path='uganda-safaris' element={<HomeFurniture></HomeFurniture>}></Route> 
<Route path='tips-to-note' element={<Tips></Tips>}></Route>   
<Route path='q-and-a' element={<QA></QA>}></Route>    
       </Routes>
       <WhatsAppIcon></WhatsAppIcon>
       <BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
